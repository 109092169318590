#posiciona {
  position:absolute ; /* posição absoluta ao elemento pai, neste caso o BODY */
  /* Posiciona no meio, tanto em relação a esquerda como ao topo */
  left: 7%; 
  top: 0%;
  width: 20%; /* Largura da DIV */

  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
  text-align: center; /* Centraliza o texto */
  z-index: 1000; /* Faz com que fique sobre todos os elementos da página */
  background-image: url('../imagens/fundosup.png');
  background-size: cover;
  background-position: center;
  background-color: #567341;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: calc(3px + 1vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
}


.App {
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
  height: 800px;
  min-height: 500px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flexC;
  flex-direction: row;
}
.Menu {
  background-color: #567341;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  height:auto;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Toposapp {
  background-color: #FECD2A;
  color: black;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: calc(3px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Titulo {
  background-color: #094215;
  color: #fff;
  background-image: url('../imagens/fundotitv.png');
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center;
  background-position-x: 100px;
  padding: 20px;
  display: flex;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.FundoTitulo {  
  background-color: #094215;
  color: #fff;
  padding: 0px;
  display: flex;
  height: 100%;
  max-height: 100%;
  min-height: 20px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.BotãoCinza {
  background-color: #585858;
  color: #fff;
  border-radius: 10px;
  border-color:#fff;
  border-style: inset;
  padding: 5px;
  width: 100px;
  height: auto;
  max-width: 100px;
  min-width: 20px;
  min-height: 15px;
  font-size: calc(3px + 1vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  -ms-flex-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.TextoOCircuito {
  background-color: #fff;
  color: black;
	max-width: 100%;
  min-height: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-height: 300px;
  font-size: calc(9px + 2vmin);
  padding: 20px;
}
.Trabalhos {
  background-color: #fff;
	width: 100%;
	height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
  padding: 1em;
}
.TrabalhoInterno {
  background-color: #fff;
  color: black;
  display: inline-block;
  align-content: space-between;
  margin: 10px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  height: auto;
  width: 30%;
  max-width: 30%;
  min-height: auto;
  font-size: calc(6px + 2vmin);
}
.Projetos {
  background-color: #fff;
	width:100%;
	height:auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  text-align: left;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  font-size: calc(7px + 2vmin);
  padding: 1em;
}
.ProjetoInterno {
  background-color: #FECD2A;
  height: 250px;
  width: 250px;
  max-width: 250px;
  max-width: 250px;
  min-width: 200px;
  min-height: 200px;
	height:auto;
  display: inline-block;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  text-align: center;
  border-radius: 1.5em;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Projetoimg {
  height: 200px;
  width: 200px;
  max-width: 200px;
  min-width: 50px;
  max-height: 200px;
  min-height: 50px;
  object-fit: scale-down;
}
.Noticias {
  background-color: #fff;
	max-width:100%;
	height:auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 1em;
}
.NoticiasTitulo {
  background-color: #fff;
  display: flex;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.Downloads {
  background-color: #fff;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.DownloadsTitulo {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.Drive {
  background-attachment: scroll;
  background-size: auto;
  background-color: #fff;
	max-width:100%;
  width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  margin: 1em;
}
.Imprensacada {
  height: 300px;
  width: 300px;
  max-width: 300px;
  min-width: 100px;
  max-height: 300px;
  min-height: 100px;
  margin: 1em;
  padding: 1em;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(5.0px + 2vmin);

}
.Imprensaimg {
  height: 240px;
  width: 300px;
  max-width: 300px;
  min-width: 100px;
  max-height: 240px;
  min-height: 80px;
  border-radius: 1.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  object-fit: cover;
}
.Cidades {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(6px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 1em;
}
.CidadesTitulo {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(6px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.Cidadescada {
  height: 300px;
  width: 240px;
  max-width: 240px;
  min-width: 100px;
  max-height: 300px;
  min-height: 100px;
  margin: 1em;
  padding: 1em;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(5.5px + 2vmin);
}
.Cidadeimg {
  height: 240px;
  width: 240px;
  max-width: 240px;
  min-width: 100px;
  max-height: 240px;
  min-height: 100px;
  border-radius: 1.5em;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
div.card {
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
.Receptivos {
  background-color: #fff;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(6px + 2vmin);
  padding: 1em;
}
.Parceiros {
  background-color: #fff;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 200px;
  font-size: calc(6px + 2vmin);
  text-align: center;
  padding: 1em;
}
.Parceiroscada {
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  font-size: calc(6px + 2vmin);
  text-align: center;
}
.Rodape {
  background-color: #787878;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(3px + 2vmin);
  align-items: center;
}
.Tonatrilha {
  background-color: #787878;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(3px + 2vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(6px + 2vmin);
}

.App-patrimoniominas {
	max-width:50%;
  min-width: 20%;
  height:auto;
  width: 100px;
  margin: 10px;
  text-align: right;
  vertical-align: text-top;
}
.App-logo {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 635.6px;
}

.Img {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-trilha {
  width: 95%;
	max-width:95%;
  min-width: 5%;
  height:auto;
  margin: 5px;
}
.App-trilha2 {
	max-width: 45%;
  min-width: 20%;
  height:auto;
  width: 45%;
  margin: 10px;
}
.App-baixar {
	max-width: 45%;
  min-width: 20%;
  height:auto;
  width: 45%;
  margin: 6px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  vertical-align: top;
  text-align: right;
}
.Textocircuito-divesquerda {
  width: 40%;
  display: flex;
  flex-direction: column;
  color: #000;
  text-align: justify;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 10px;
  font-size: calc(5px + 2vmin);
  padding: 20px;
}
.Textocircuito-divdireita {
  width: 40%;
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 2vmin);
  color: black;
  text-align: justify;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(6px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(6px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #000;
	}
a:hover {
	text-decoration:none; 
	color:#fff; 
	}

.action{
    padding: 0px;
    margin: 10px;
    align-items: center;
    display: inline-block;
    border-radius: 4px;;
  }

  .react-slideshow-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .react-slideshow-container .nav {
    z-index: 10;
  }
  
  .react-slideshow-container .default-nav {
    height: 30px;
    background: rgba(255, 255, 255, 0.6);
    width: 30px;
    border: none;
    text-align: center;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  

  
  .react-slideshow-container .default-nav:hover,
  .react-slideshow-container .default-nav:focus {
    background: #fff;
    color: #666;
    outline: none;
  }
  
  .react-slideshow-container .default-nav.disabled:hover {
    cursor: not-allowed;
  }
  
  .react-slideshow-container .default-nav:first-of-type {
    margin-right: -30px;
    border-right: none;
    border-top: none;
  }
  
  
  .react-slideshow-container .default-nav:last-of-type {
    margin-left: -30px;
  }
  
  
  .react-slideshow-container + ul.indicators {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 20px;
  }
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .react-slideshow-container + ul.indicators li {
    display: inline-block;
    position: relative;
    width: 7px;
    height: 7px;
    padding: 5px;
    margin: 0;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator {
    border: none;
    opacity: 0.25;
    cursor: pointer;
    background: transparent;
    color: transparent;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    content: '';
    background: #000;
    text-align: center;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:hover,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator.active,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:focus {
    opacity: 0.75;
    outline: none;
  }
  
  .react-slideshow-fade-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
    position: relative;
    opacity: 0;
  }
  
  .react-slideshow-wrapper.slide {
    width: 100%;
    overflow: hidden;
  }
  
  .react-slideshow-wrapper .images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .react-slideshow-zoom-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .react-slideshow-zoom-wrapper .zoom-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    overflow: hidden;
    background-color: '#FECD2A';
  }
  
  .react-slideshow-zoom-wrapper .zoom-wrapper > div {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }




/*BUTTON BURGER */
  /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position:relative;
  left: 00%; 
  top: 0%;
  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
  left: 00%; 
  top: 0%;
  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);

}

